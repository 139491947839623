<template>
  <div>
    <SlideShow :bannerList="bannerList"></SlideShow>
    <div class="searchBar">
      <Search
        type="5"
        :keys="getListParams.key"
        @searchKeyFun="searchKeyFun"
      ></Search>
    </div>
    <template>
      <div class="main_Div contBox">
        <PsClassifyMenu
          :keys="getListParams.key"
          @searchKeyFun="searchKeyFun"
          @selectParams="selectParams"
          @changeData="changeData"
          @changeMenu="changeMenu"
        >
        </PsClassifyMenu>
        <div class="ListBox">
          <PsList
            :photoList="psListObj.data"
            :listStatus="listStatus"
            ref="list"
            @nextPage="nextPage"
          ></PsList>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import SlideShow from "@/components/SlideShow";
import Search from "@/components/Search";
import PsList from "@/components/PsList";
import PsClassifyMenu from "@/components/PsClassifyMenu";
import { getBanner } from "../../Api";
import { mapGetters } from "vuex";

export default {
  name: "MaterialIndex",
  components: {
    SlideShow,
    Search,
    PsClassifyMenu,
    PsList,
  },
  data() {
    return {
      bannerList: [],
      loading: false,
      timer: null,
      getListParams: {
        id: 1196,
        child: null,
        free: 0,
        page: 1,
        px: 0,
        reCom: 0,
        format: "",
        key: "",
        per_page: 30,
      },
      listStatus: {
        isData: true,
        isNull: false,
      },
    };
  },
  created() {
    this.initData();
		
  },
  methods: {
    //下一页
    nextPage() {
			if (this.loading) {
				return
			}
      this.getListParams.page += 1;
    },
    initData() {
      const params = {
        position: 1035,
        cate: 1300,
      };
      getBanner(params).then((res) => {
        this.bannerList = res.data;
      });
    },
    //选择其他属性
    selectParams(data) {
      this.$refs.list.clear();
      this.getListParams[data.type] = data.value;
      this.getListParams.page = 1;
      this.currentOffset = 0;
    },
    //修改请求数据
    changeData(data) {
      // this.$refs.list.clear();
      this.getListParams.page = 1;
      this.getListParams = data;
      this.currentOffset = 0;
    },
    //选择菜单
    changeMenu(data) {
      this.$refs.list.clear();
      this.getListParams.page = 1;
      this.getListParams.id = data;
      
    },
    //  选定分页
    setPage(val) {
      this.getListParams.page = val;
      //回到顶部
      cancelAnimationFrame(this.timer);
      const self = this;
      self.timer = requestAnimationFrame(function fn() {
        const oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop - 100;
          self.timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(self.timer);
        }
      });
    },
    //  搜索
    searchKeyFun(keys) {
      this.getListParams.page = 1;
      this.getListParams.key = keys;
      this.currentOffset = 0;
      this.$refs.list.clear();
    },
  },
  computed: {
    ...mapGetters(["psListObj"]),
  },
  watch: {
    getListParams: {
      handler() {
				if (this.loading) {
					return
				}
        this.loading = true;
        (this.listStatus.isData = true), (this.listStatus.isNull = false);
        this.$store
          .dispatch("app/getPsList", this.getListParams)
          .then((res) => {
            this.loading = false;
            const { data } = res;
            if (data.total == 0) {
              this.$refs.list.imgList = [];
              this.listStatus.isNull = true;
            } else if (data.current_page == data.last_page) {
              this.listStatus.isData = false;
            }
          }).catch(()=>{
						this.loading = false;
					});
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
::v-deep .is-animated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pageBox {
  width: 100%;
  text-align: center;
}

.searchBar {
  position: absolute;
  width: 100%;
  min-width: 1400px;
  top: 2rem;
  z-index: 8;
}

.contBox {
  position: relative;
  top: -0.66rem;
  z-index: 8;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ff9e58;
  border-radius: 50%;
}
</style>